import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { Typography, Grid, Box } from "@material-ui/core"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import {
  SiteTitle,
  Section,
  RichText,
  ActionButton,
  BackButton,
  OutboundLink,
  MenuDrawer,
  Copyright,
} from "../components"
import { accent, dark } from "../theme/colors"
import { cardBoxShadow, cardBorderRadius } from "../styles/card"
import changeShadowColor from "../util/changeShadowColor"
import theme from "../theme"

const BlendBackground = styled(BackgroundImage)`
  background-blend-mode: color;
`

const LogoWrapper = styled.div`
  position: relative;
  top: -${theme.spacing(3)}px;
  background-color: white;
  padding: ${theme.spacing(3)}px;
  ${cardBoxShadow};
  ${cardBorderRadius};
`

const getHumanReadableUrl = (url) => {
  const { host, pathname } = new URL(url)
  const path = pathname === "/" ? "" : pathname
  return host.replace("www.", "") + path
}

export const SponsorPage = ({ data }) => {
  const { name, text, sponsor, background, color } = data.contentfulSponsorPage
  const backgrounds = [`linear-gradient(${dark}, ${dark})`, background.fluid]
  const sponsorUrl = getHumanReadableUrl(sponsor.url)

  const Card = styled.div`
    background-color: ${color ?? accent};
    padding: ${theme.spacing(3)}px;
    padding-top: 0;
    ${changeShadowColor(cardBoxShadow, color ?? accent)};
    ${cardBorderRadius};
  `

  return (
    <React.Fragment>
      <SiteTitle title={name} />
      <MenuDrawer></MenuDrawer>

      <Box
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        overflow="hidden"
      >
        {/* Background image */}
        <BlendBackground fluid={backgrounds}>
          <Section
            slant="background"
            slantCenter
            horizontalSpacing={3}
            verticalSpacing={20}
          >
            <BackButton to="../../" color="secondary" />
          </Section>
        </BlendBackground>

        {/* Logo & Title Card */}
        <Section
          horizontalSpacing={3}
          verticalSpacing={3}
          noPaddingTop
          topSlantCenter
        >
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={5} xl={3}>
              <Card>
                <LogoWrapper>
                  <Image fluid={sponsor.image.fluid}></Image>
                </LogoWrapper>
                <Typography variant="h4" align="center" color="secondary">
                  Sponsort Demos
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Section>

        {/* Rich Text Section */}
        <Section horizontalSpacing={3} verticalSpacing={1.5}>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={6} xl={4}>
              <RichText>{text.json}</RichText>
            </Grid>
          </Grid>
        </Section>

        <Box display="flex" flexGrow={1}></Box>
        <Section slant="dark" slantCenter verticalSpacing={0}>
          <Box display="flex" justifyContent="center" mt={6}>
            <ActionButton
              size="medium"
              href={sponsor.url}
              component={OutboundLink}
            >
              Ga naar {sponsorUrl}
            </ActionButton>
          </Box>
        </Section>
        <Section color="dark" noPaddingTop>
          <Copyright />
        </Section>
      </Box>
    </React.Fragment>
  )
}

export const query = graphql`
  query getSponsorPage($slug: String!) {
    contentfulSponsorPage(slug: { eq: $slug }) {
      name
      slug
      color
      text {
        json
      }
      background {
        fluid(maxWidth: 1920, maxHeight: 1080, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      sponsor {
        name
        url
        image {
          id
          title
          fluid(maxWidth: 1200) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default SponsorPage
